import { FormControl } from "@angular/forms";

export const YEARMONTH_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM',
    },
    display: {
        dateInput: 'YYYY-MM',
        monthYearLabel: 'YYYY MM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY MMMM',
    },
};

export const YEARMONTHDAY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'YYYY-MM-DD',
        monthYearLabel: 'YYYY MM',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY MMMM',
    },
};

export const REPLACECASH_USE_RANGE = {
    a: '全部',
    s: '服務',
    g: '商品',
    o: '指定品項',
}

export const STORECASH_INOUT_TYPE = {
    A: '儲值',
    B: '消費',
    C: '購買商品券',
    D: '購買優惠券',
    E: '積點兌換',
    F: '調整儲值金(增加)',
    G: '歸還儲值金',
    H: '加值優惠券',
    I: '預付訂金',
}

export const REPLACECASH_INOUT_TYPE = {
    A: '購買',
    B: '消費',
    C: '積點兌換',
    D: '歸還',
    E: '加值',
}

export const MEMBER_EDIT_PERMISSION = {
    "NOT_ALLOW": 0,
    "ALL_MEMBER": 1,
    "ALL_MEMBER_REMARK": 2,
    "SELF_MEMBER": 3,
    "SELF_MEMBER_REMARK": 4,
    "SELF_AND_OTHER_REMARK": 5,
}

export const MEMBER_VIEW_PERMISSION = {
    "NOT_ALLOW": 0,
    "ALL_MEMBER": 1,
    "ALL_MEMBER_NOT_CONTACT": 2,
    "SELF_MEMBER": 3,
    "SELF_MEMBER_NOT_CONTACT": 4,
    "SELF_AND_OTHER_NOT_CONTACT": 5,
}

export const HOLIDAY_TYPE = {
    "ns": '關閉預約',
    "pl": '事假',
    "si": '病假',
    "ph": '排休',
    "of": '公假',
}

export const RESERVATION_TYPE = {
    "wait": '待確認',
    "reservation": '已接受',
    "hasshow": '已到店',
    "confirm": '已確認',
    "checkout": '已開單',
    "noshow": '未到店',
    "refusecancel": '已取消/拒絕',
    "delete": '已刪除',
}

export const RESERVATION_SOURCE = {
    "pc": 'SayDou 1.0',
    "web2": '網頁',
    "line": 'LINE',
    "fb": 'Facebook',
    "phone": '手機APP',
    "pad": '平板',
    "google": 'Google'
}

export const BASEDATA_TYPE = {
    'STORE': 'stores', //門店
    'CATEGORY': 'category', //品項分類
    'DEPARTMENT': 'departments', //部門
    'POSITION': 'positions', //職位
    'MANAGEMENT_POSITION': 'management_positions', //管理層職位
    'LEVEL': 'levels', //級別
    'TAGS': 'tags', //會員標籤
    'SETTING': 'settings', //客戶設定
    'STAFF': 'staffs', //工作人員
    'PAYMENT': 'payments', //付款方式
    'MEMBER_LEVEL': 'member_level_settings', //會員等級列表
    'MEMBER_SOURCE': 'source', //會員來源
    'MEMBER_JOB': 'jobs', //會員職業
    'BRAND': 'brands', //店販品牌
    'GROUP_BRAND': 'group_brands', //店販品牌
    'MATERIAL_CATEGORY': 'material_category', //耗材分類
    'BONUS_MODEL': 'bonus_model', //抽成模板
    'TICKET_BONUS_MODEL': 'ticket_bonus_model', //抽成模板
    'APPOINTMENT_SERVICE': 'appointment_services', //預約服務列表
    'STORECASH_ACH_SETTING': 'storecash_ach_setting', //儲值業績抽成設定
    'TICKET_ACH_SETTING': 'ticket_ach_setting', //儲值業績抽成設定
    'THIRDPARTY_PAY': 'thirdparty_pay', //第三方支付資訊
    'SERVICE_LIMIT': 'service_limits', //服務上限
    'CATEGORY_TWO_LEVEL': 'category-two-level', //分類(二階層)
    'PET_CATEGORY': 'pet-category', //分類(二階層)
    'BRANCH': 'branch', //分類(二階層)
}

export const CUSTOMER_TYPE = {
    "LINE_LITE": 'line_lite',
    "NORMAL": 'group',
    "LINE": 'line',
}

export const LEVEL_LOG_TYPE = {
    add: '工作人員新增',
    delete: '工作人員刪除',
    buy: '購買',
    upgrade: '達到門檻，給予會員等級',
    expire: '等級到期',
    cancel: '訂單更動/刪除，移除會員等級',
}

export enum TICKET_RECORD_TYPE {
    BUY = "A",
    USED = "B",
    POINT = "C",
    GIFT = "D",
    RETURN = "E",
    MULTICAST = "F",
};

export enum RPCASH_RECORD_TYPE {
    BUY = "A",
    USED = "B",
    POINT = "C",
    RETURN = "D",
    STORE = "E",
    MULTICAST = "F",
};

export enum CARD_RECORD_TYPE {
    STORE = "A",
    USED = "B",
    BUY_TICKET = "C",
    BUY_RPCASH = "D",
    POINT = "E",
    ADJUST_IN = "F",
    ADJUST_OUT = "G",
    STORE_RPCASH = "H",
    BUY_DEPOSIT = "I",
};

export function cellPhoneBarcodeValidator(control: FormControl) {
    let num = control.value;
    if (num == '') {
        return null;
    }
    if (typeof num !== 'string') {
        return {
            cernum: {
                error: true
            }
        };
    }
    const n = num.toString()
    const regex = /^\/[\dA-Z.\-+]{7}$/
    if (regex.test(n)) {
        return null;
    } else {
        return {
            cernum: {
                error: true
            }
        };
    }
}

export function taxnumValidator(control: FormControl) {
    let taxnum = control.value;
    if (taxnum == '') {
        return null;
    }
    var invalidList = "00000000,11111111";
    if (/^\d{8}$/.test(taxnum) == false || invalidList.indexOf(taxnum) != -1) {
        return {
            taxnum: {
                error: true
            }
        };
    }

    var validateOperator = [1, 2, 1, 2, 1, 2, 4, 1],
        sum = 0,
        calculate = function (product) { // 個位數 + 十位數
            var ones = product % 10,
                tens = (product - ones) / 10;
            return ones + tens;
        };
    for (var i = 0; i < validateOperator.length; i++) {
        sum += calculate(taxnum[i] * validateOperator[i]);
    }

    if (sum % 5 == 0 || (taxnum[6] == "7" && (sum + 1) % 5 == 0)) {
        return null;
    } else {
        return {
            taxnum: {
                error: true
            }
        };
    }
}

export const SYSTEM_TYPE = {
    "PETTO": 'petto',
    "SAYDOU": 'saydou',
};
