import { Component, OnInit, ViewChild, Input, ElementRef, HostListener, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeleteConfirmDialog } from '../common/delete-confirm/delete-confirm.component';
import { DeleteMessageConfig } from 'src/app/model/delete-message';
import { ContractService } from 'src/app/services/contract.service';
import { ZIPCODE } from 'src/app/const/zipcode';
import SignatureEvent from 'signature_pad';
import { CommonService } from 'src/app/services/common/common.service';
import { OverlayContainer } from '@angular/cdk/overlay';

export interface DialogData {
    signature: string;
}

@Component({
    selector: 'online-contract',
    templateUrl: './online-contract.component.html',
    styleUrls: ['./online-contract.component.scss']
})
export class OnlineContractComponent implements OnInit {
    @ViewChild('signPadCanvas') signPadCanvas: any;
    @ViewChild('signArea') signArea: ElementRef;
    @HostListener('window:resize', ['$event']) onResize(event) {
        this.resizeCanvas();
    }
    imageDataBase64 = '';
    signPad: any;
    agreeForm: FormGroup;
    baseForm: FormGroup;
    signForm: FormGroup;
    bill: any = {};
    billDetail = [];
    systemDetail = [];
    hardwareDetail = [];
    systemTotal = 0;
    hardwareTotal = 0;
    totalPrice = 0;
    contractId = '';
    customerInfo = {};
    contractDirection = {
        start: '',
        end: '',
        direction: 0,
    };
    suadInfo = {};
    checkoutSuccess = 0;
    signReady = 0;
    contractDeletedShow = 0;
    contactMissingShow = 0;
    bigSealUrl = '';
    smallSealUrl = '';
    baseUrl: string = "http://sayWebDataFeed";
    area = false;
    zipcode = ZIPCODE;
    objectKeys = Object.keys;
    theme = "petto-theme";
    primaryColor = "#AF4585";
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private cs: ContractService,
        public dialog: MatDialog,
        private router: Router,
        private ccs: CommonService,
        private overlayContainer: OverlayContainer,

    ) {
        this.ccs.currentHost.subscribe((host) => {
            this.theme = host.theme;
            this.primaryColor = host.primaryColor;
            // this.logoPngUrl = host.logoPngUrl;
            // this.backgroundUrl = host.backgroundUrl;
            document.documentElement.style.setProperty('--primary-color', this.primaryColor);
        });
        this.overlayContainer.getContainerElement().classList.add(this.theme);

    }
    ngOnInit(): void {
        this.baseForm = this.fb.group({
            contract_id: [{ value: '' }, [Validators.required]],
            custname: [{ value: '', disabled: false }, [Validators.required]],
            siname: [{ value: '', disabled: false }, [Validators.required]],
            addrCity: ['', [Validators.required, Validators.maxLength(100)]],
            addrArea: ['', [Validators.required, Validators.maxLength(100)]],
            addrCode: ['', [Validators.required, Validators.maxLength(100)]],
            address: [{ value: '', disabled: false }, [Validators.required]],
            phone: ['', [Validators.required, Validators.maxLength(13), Validators.pattern(/^\d{2,2}-\d{1,10}|^09\d{8}$/)]],
            // phone: ['', [Validators.required, Validators.maxLength(13), Validators.pattern(/^\d{2,2}-\d{1,10}|^\d{4,4}-\d{1,8}$/)]],
            email: [{ value: '', disabled: false }, [Validators.required, Validators.email]],
            taxnum: ['', this.taxnumValidator],
            remark: [''],
        });

        this.contractId = this.route.snapshot.paramMap.get('id');
        this.fetchBaseData(this.contractId);
        this.agreeForm = this.fb.group({
            contract_content_agree: [false, Validators.requiredTrue]
        });
        this.signForm = this.fb.group({
            signature: ['', Validators.required]
        });
    }
    ngAfterViewInit() {
        this.signPad = new SignatureEvent(this.signPadCanvas.nativeElement, {
            'minWidth': 2,
            'maxWidth': 2,
        });
        this.signPad.addEventListener("endStroke", () => {
            this.drawComplete();
        });
        this.resizeCanvas();
    }

    // 螢幕寬改變調整簽名範圍則清除簽名
    resizeCanvas() {
        let signAreaWidth = this.signArea.nativeElement.offsetWidth;
        let signAreaHeight = this.signArea.nativeElement.offsetHeight;
        this.signPadCanvas.nativeElement.height = signAreaHeight;
        this.signPadCanvas.nativeElement.width = signAreaWidth;
        this.clear();
    }

    cityChange(e) {
        this.area = this.zipcode[e.value];
    }

    areaChange(e) {
        if (this.area[e.value]) {
            this.baseForm.get('addrCode').setValue(this.area[e.value]);
        }
    }

    //統編判斷
    taxnumValidator(control: FormControl) {
        let taxnum = control.value;
        if (taxnum == '') {
            return null;
        }
        var invalidList = "00000000,11111111";
        if (/^\d{8}$/.test(taxnum) == false || invalidList.indexOf(taxnum) != -1) {
            return {
                taxnum: {
                    error: true
                }
            };
        }

        var validateOperator = [1, 2, 1, 2, 1, 2, 4, 1],
            sum = 0,
            calculate = function (product) { // 個位數 + 十位數
                var ones = product % 10,
                    tens = (product - ones) / 10;
                return ones + tens;
            };
        for (var i = 0; i < validateOperator.length; i++) {
            sum += calculate(taxnum[i] * validateOperator[i]);
        }

        if (sum % 10 == 0 || (taxnum[6] == "7" && (sum + 1) % 10 == 0)) {
            return null;
        } else {
            return {
                taxnum: {
                    error: true
                }
            };
        }
    }

    fetchBaseData(cid) {
        this.cs.getContract(cid).subscribe(res => {
            if (res.status) {
                this.signReady = res.data.signed;
                this.bigSealUrl = res.data.bigSign;
                this.smallSealUrl = res.data.smallSign;
                this.baseForm.get('contract_id').setValue(res.data.contract_id);
                this.baseForm.get('remark').setValue(res.data.remark);
                this.customerInfo = res.data.customer;
                this.baseForm.get('custname').setValue(this.customerInfo['cstnam']);
                if (this.customerInfo['contacts']) {
                    this.baseForm.get('siname').setValue(this.customerInfo['contacts']['contactName'])
                    this.baseForm.get('phone').setValue(this.customerInfo['contacts']['contactPhone'])
                    this.baseForm.get('email').setValue(this.customerInfo['contacts']['contactEmail'])
                    let cityValue = { value: this.customerInfo['contacts']['city'] };
                    let areaValue = { value: this.customerInfo['contacts']['area'] };
                    if (cityValue.value && areaValue.value) {
                        this.cityChange(cityValue);
                        this.areaChange(areaValue);
                    }
                    this.baseForm.get('addrCity').setValue(this.customerInfo['contacts']['city'])
                    this.baseForm.get('addrArea').setValue(this.customerInfo['contacts']['area'])
                    this.baseForm.get('address').setValue(this.customerInfo['contacts']['address']);
                } else {
                    this.contactMissingShow = 1;
                }
                this.bill = res.data.bill;
                this.baseForm.get('taxnum').setValue(this.bill['tax_no']);
                this.billDetail = this.bill['items'];
                this.totalPrice = this.bill['rprice'];
                this.systemDetail = this.bill.items.filter(x => {
                    return x.fee?.type == 's' || x.version_id > 0;
                });
                if (this.systemDetail.length > 0) {
                    this.systemTotal = this.systemDetail.map(x => { return parseInt(x.tprice); }).reduce((a, b) => a + b) - parseInt(this.bill.discot);
                }
                let cloudResult = false;
                this.systemDetail.forEach((x, i) => {
                    if (x.version) {
                        if (x.version.version_prefix.includes('tcloud')) {
                            cloudResult = true;
                        }
                    }
                });
                if (cloudResult) {
                    this.systemDetail.push(
                        {
                            'item__': '雲市集補助',
                            'tprice': -Math.floor(this.totalPrice / 2),
                        }
                    )
                    this.totalPrice += -Math.floor(this.totalPrice / 2);
                };
                this.hardwareDetail = this.bill.items.filter(x => x.fee?.type == 'h');
                if (this.hardwareDetail.length > 0) {
                    this.hardwareTotal = this.hardwareDetail.map(x => { return parseInt(x.tprice); }).reduce((a, b) => a + b);
                }
                this.suadInfo = res.data.suad;
                let start = moment(res.data.start).subtract('1911', 'years');
                let end = moment(res.data.end).subtract('1911', 'years');
                this.contractDirection['start'] = start.format('yyy年MM月DD日');
                this.contractDirection['end'] = end.format('yyy年MM月DD日');
                this.contractDirection['direction'] = end.diff(start, 'months') + 1;
            } else {
                this.contractDeletedShow = 1;
                // this.router.navigate(["/"]);
            }
        })
    };

    drawComplete() {
        console.log('draw success');
        this.signForm.get('signature').setValue(this.signPad.toDataURL());
        this.imageDataBase64 = this.signPad.toDataURL();
    }
    clear() {
        console.log('draw clean');
        this.signForm.get('signature').setValue('');
        this.signPad.clear();
    }
    saveConfirm() {
        let config: DeleteMessageConfig = {
            title: `合約確認`,
            confirmMessage: `確認合約資料沒有問題？`,
            confirmText: '確認',
            cancelText: '否',
            countdown: 3
        };

        const dialogRef = this.dialog.open(DeleteConfirmDialog, {
            data: config,
            minWidth: '30%',
            autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.emitToFather();
            }
        });
    }
    emitToFather() {
        let imageData = this.imageDataBase64;
        let body = {
            'baseInfo': this.baseForm.value,
            'signature': imageData,
        }
        this.cs.restoreContract(body).subscribe(
            data => {
                if (data.status) {
                    this.checkoutSuccess = 1;
                } else {
                    this.contractDeletedShow = 1;
                }
            }
        );
    }

    showSign() {
        const dialogRef = this.dialog.open(SignatureDialog, {
            data: { signature: this.imageDataBase64 },
            minWidth: '30%',
            autoFocus: false,
        });
        dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
        });
    }

    download() {
        this.cs.downloadExportRecord(this.contractId).subscribe(res => {
            if (res.status) {
                window.open(res.url, '_blank');
            }
        });
    }
    redirectWeb() {
        this.router.navigate(["/management/dashboard/overview"]);
    };
}

@Component({
    selector: 'contract-content',
    templateUrl: './contract-content.html',
    styleUrls: ['./contract-content.component.scss']

})
export class ContractContent {
    @Input() customerInfoData = {};
    @Input() totalPrice = 0;
    @Input() billDetail = [];
    @Input() systemDetail = [];
    @Input() hardwareDetail = [];
    @Input() systemTotal = 0;
    @Input() hardwareTotal = 0;
    @Input() bill: any = {};
    @Input() customerInfo = {};
    @Input() contractDirection = {};
    @Input() suadInfo = {};
    @Input() bigUrl = '';
    @Input() smallUrl = '';
    today = '';
    constructor(
    ) {
        let year = parseInt(moment().format('YYYY'));
        let chineseYear = year - 1911;
        this.today = "中華民國 " + chineseYear + "年" + moment().format(' MM月 DD日');
    }
}
@Component({
    selector: 'signature-dialog',
    templateUrl: 'signature-dialog.html',
})
export class SignatureDialog {
    constructor(
        public dialogRef: MatDialogRef<SignatureDialog>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) { }

    //   onNoClick(): void {
    //     this.dialogRef.close();
    //   }
}
