<div style="height: 480px;overflow: auto;">
    <p style="margin:auto 0pt; text-align:center; line-height:24pt">
        <img src="./assets/images/online-contract/aoito-l5lmt.001.png" alt="slogan1.png" style="margin-top:30pt; margin-left:-0.5pt; -aw-left-pos:-0.5pt; -aw-rel-hpos:margin; -aw-rel-vpos:line; -aw-top-pos:30pt; -aw-wrap-type:none;width:80%;">
        <span style="font-weight:bold; letter-spacing:5pt">
            &nbsp;
        </span>
    </p>
    <p style="margin:auto 0pt; text-align:center; page-break-after:avoid; font-size:36pt">
        <span class="font-small">
            軟體
        </span>
        <span class="font-small">
            服務合約書
        </span>
    </p>
    <p style="margin:auto 0pt; line-height:20pt">
        <span style="font-size:14pt">
            &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <span style="width:6.5pt; display:inline-block">
            &nbsp;
        </span>
        <span style="width:24pt; display:inline-block"
        >&nbsp;
        </span>
        <span style="width:24pt; display:inline-block">
            &nbsp;
        </span>
        <span style="width:24pt; display:inline-block">&nbsp;</span>
        <span style="width:24pt; display:inline-block">&nbsp;</span>
        <span style="width:24pt; display:inline-block">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:20pt">
        <span >&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; text-align:center; line-height:24pt">
        <span style="font-size:13pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; text-align:center; line-height:24pt">
        <span style="font-size:13pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:24pt">
        <span style="font-size:13pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:24pt">
        <br style="page-break-before:always; clear:both">
    </p>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">一、</span>
        <span style="font-size:18pt">立合約書人</span>
        <br>
        <br>
    </p>
    <div style="padding-left: 20px;font-size:24px;">
        <div>
            {{ customerInfo['custname'] }}
        </div>
        <img src="./assets/images/online-contract/aoito-l5lmt.004.png" width="392" height="2" alt="線條" style="width:90%;-aw-left-pos:54.19pt; -aw-rel-hpos:margin; -aw-rel-vpos:line; -aw-top-pos:52.32pt; -aw-wrap-type:topbottom">
    </div>
    <p style="margin-top:6pt; margin-bottom:0pt; line-height:6pt">
        <span style="font-size:13pt">&nbsp;</span>
    </p>
    <p style="margin:auto 10pt; line-height:16pt">
        <span style="width:24pt;">&nbsp;茲因乙方 安騰創新生活科技有限公司 提供「 SayDou 神美」雲端數位服務（</span>
        <a href="http://saydou.com/" style="text-decoration:none">
            <span style="font-size:12pt; text-decoration:underline; color:#0563c1">saydou.com</span>
        </a>
        <span style="font-size:12pt">，以下簡稱「本服務」），係專為 美容美髮相關業者研發，基於一站式雲端數據整合數位沙龍系統服務，甲方擬使用「本服務」及相關事宜，雙方同意就此訂定本服務合約，並共同遵守合約條款如下列各章節所述，以資互信遵守。</span>
    </p>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid;line-height:26pt">
        <span style="font-size:20pt">二、</span>
        <span style="font-size:18pt">合約期間</span>
    </p>
    <p style="margin-left: 38pt; line-height:24pt">
        <span style="font-size:13pt">中華民國 </span>
        <span style="font-size:13pt; text-decoration:underline">
            {{ contractDirection['start'] }}
        </span>
        <span style="font-size:13pt">起，至 中華民國 </span>
        <span style="font-size:13pt; text-decoration:underline">{{ contractDirection['end'] }}</span>
        <span style="font-size:13pt">止。</span>
    </p>
    <div style="padding:50px 20px;">
        <div style="display:flex;width:700px;margin:auto;justify-content: space-between;width:80%;">
            <div class="font-weight-bold" style="margin:20px;">{{ customerInfo['custname'] }}</div>
            <div style="margin:20px;font-size:18px;">(以下簡稱甲方)</div>
        </div>
        <div style="display:flex;width:700px;margin:auto;justify-content: space-between;width:80%;">
            <div class="font-weight-bold" style="margin:20px;">安騰創新生活科技有限公司</div>
            <div style="margin:20px;font-size:18px;">(以下簡稱乙方)</div>
        </div>
    </div>
    <div style="margin:50px 0">
        <table style="width:80%;margin:auto;border-collapse: collapse;">
            <tr>
                <th>項目</th>
                <th>單價</th>
                <th>數量</th>
                <th>費用</th>
            </tr>
            <tr *ngIf="systemDetail.length > 0" style="border-top:1px solid #6f727d;font-weight:bold">
                <td colspan="2">系統費用小計</td>
                <td colspan="2" style="text-align:end">
                    {{systemTotal|number:'1.0'}}
                </td>
            </tr>
            <tr *ngFor="let item of systemDetail" style="border-top:1px solid #6f727d">
                <td style="padding-left:15px">
                    {{ item['item__'] }}
                </td>
                <td style="text-align:end">
                    {{ item['price_']|number:'1.0' }}
                </td>
                <td style="text-align:end">
                    {{ item['quantity']|number:'1.0' }}
                </td>
                <td style="text-align:end">
                    {{ item['tprice']|number:'1.0' }}
                </td>
            </tr>
            <tr *ngIf="bill['discot'] > 0" style="border-top:1px solid #6f727d">
                <td style="padding-left:15px">
                    系統費用折扣 {{ bill['disper'] % 10 == 0 ? bill['disper'] / 10 : bill['disper'] }}折
                </td>
                <td style="text-align:end">
                </td>
                <td style="text-align:end">
                </td>
                <td style="text-align:end">
                    -{{ bill['discot']|number:'1.0' }}
                </td>
            </tr>
            <tr *ngIf="hardwareDetail.length > 0" style="border-top:1px solid #6f727d; font-weight:bold">
                <td colspan="2">硬體與其他費用小計</td>
                <td colspan="2" style="text-align:end">
                    {{hardwareTotal|number:'1.0'}}
                </td>
            </tr>
            <tr *ngFor="let item of hardwareDetail" style="border-top:1px solid #6f727d">
                <td style="padding-left:15px">
                    {{ item['item__'] }}
                </td>
                <td style="text-align:end">
                    {{ item['price_']|number:'1.0' }}
                </td>
                <td style="text-align:end">
                    {{ item['quantity']|number:'1.0' }}
                </td>
                <td style="text-align:end">
                    {{ item['tprice']|number:'1.0' }}
                </td>
            </tr>
            <tr *ngIf="bill['coupon_value'] > 0" style="border-top:1px solid #6f727d">
                <td style="font-weight:bold">
                    {{ bill['coupon_name']}}
                </td>
                <td style="text-align:end">
                </td>
                <td style="text-align:end">
                </td>
                <td style="text-align:end; font-weight:bold">
                    -{{ bill['coupon_value']|number:'1.0' }}
                </td>
            </tr>
            <tr style="border-top:1px solid #6f727d;font-weight:bold">
                <td></td>
                <td></td>
                <td >總計</td>
                <td style="text-align:end">
                    {{ totalPrice|number:'1.0' }}
                </td>
            </tr>
        </table>
    </div>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >合約期限屆滿前一個月時，若雙方無正式通知再行續約，則視為合約終止，其後亦同。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方在租用期滿前十五日內，乙方應盡告知甲方租用期滿之續約義務，若甲方未續繳下期費用時，乙方應依合約約定暫停甲方使用服務，待繳清費用後，恢復權利。如逾三十日仍未繳清，即視為合約終止，甲方需繳交逾期租賃費用差額，以日計算。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方租用「本服務」以收到甲方款項後，乙方交付管理帳號及密碼為系統起租日，系統起租日之租用費用不計，乙方提供系統使用到期日資訊於「本服務」管理後台介面。</span>
        </li>
    </ol>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">三、</span>
        <span style="font-size:18pt">服務費用</span>
    </p>
    <ol style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:8.15pt; font-size:12pt">
            <span >乙方保障甲方租用費用於合約期間內不會異動。 若甲乙雙方於合約簽訂後，乙方於合約簽訂日後十五日內，未收足甲方之應付款項，乙方有權終止合約，甲方不得異議，亦不得請求退款。 </span>
        </li>
    </ol>
    <p style="margin-top:0pt; margin-left:24pt; margin-bottom:0pt; line-height:20pt">
        <span style="font-size:12pt">&nbsp;</span>
    </p>
    <p style="margin-top:0pt; margin-left:24pt; margin-bottom:0pt; line-height:30pt">
        <span style="width:24pt; display:inline-block">&nbsp;</span>
        <span style="font-size:15pt">合計總費用(含稅)&nbsp;&nbsp;&nbsp; </span>
        <span style="font-size:15pt">新台幣</span>
        <span style=" font-size:15pt; text-decoration:underline">&nbsp;&nbsp;&nbsp;{{ totalPrice|number:'1.0' }}&nbsp;&nbsp;&nbsp;
        </span>
        <span style="font-size:15pt">元整。</span>
    </p>
    <div style="margin-left:50pt;">
        <span style="font-size:15pt">備註說明</span>
        <div style="margin-top:30px;font-weight:bold;font-size:15px;">
            {{ customerInfo['remark'] }}
        </div>
        <img src="./assets/images/online-contract/aoito-l5lmt.004.png" width="392" height="2" alt="線條" style="width:90%;-aw-left-pos:54.19pt; -aw-rel-hpos:margin; -aw-rel-vpos:line; -aw-top-pos:52.32pt; -aw-wrap-type:topbottom">
    </div>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">四、</span>
        <span style="font-size:18pt">服務內容</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」數位服務系統教育。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」系統設定及設備安裝、連網服務。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」客戶系統基礎資料設定。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」問題諮詢專人服務。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」網頁端數位服務入口管理者與使用者帳號與密碼。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」手機裝置使用者帳號與密碼，APP下載連結。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」iPad平板裝置使用者帳號與密碼，APP下載連結。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」數位服務工具基本使用手冊。</span>
        </li>
    </ol>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">五、</span>
        <span style="font-size:18pt">服務範圍</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」係指由乙方提供軟硬體設備，包括應用程式、雲端伺服器主機、資料庫、電力設施、網路連接，以及相關之技術支援、教育訓練、資料備份，供甲方經由網路從乙方手機、平板、電腦裝置使用應用程式之服務。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方應維持資料庫及整體伺服系統的年可靠度達 99.9%。系統可靠度的定義為硬體及網路的可用性，並非每個獨立服務的運作時間。第三方的監控報告可能不會被採用，因為監控者的網路穩定度，頻寛及資料傳輸的可用度皆不同。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >系統上線導入服務乃為一次性服務，乙方始導入後，協助甲方驗證至薪資數據項目無誤為限。倘若甲方因</span>
            <span style="font-weight:bold">人事異動或</span>
            <span style="font-weight:bold">執行業務、</span>
            <span style="font-weight:bold">地點變動等因素，必需重新導入系統時，其延伸的費用應由甲方負擔。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方應負責甲方連線至乙方伺服器之相關設備與網路環境，始能使用「本服務」。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >「本服務」提供 24*7*365小時，全年無休連線服務，遇有障礙儘速修復，但網際網路會因距離、離尖峰時間及客戶頻寬等因素影響，因此「本服務」不提供保証頻寬。 </span>
        </li>
    </ol>
    <ol start="6" type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方應推派至少一位，至多兩位代表人，負責與乙方進行導入作業與相關設定事宜，甲方之代表人如有異動，需負責業務交接及主動通知乙方。</span>
        </li>
    </ol>
    <ol start="7" type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方有義務研發或採用更新技術，以維護應用程式之順利運作，並保障應用程式資料之完整性及安全性。乙方需提昇其應用程式之功能、便利、穩定、完整及更新，其提升之實際內容由乙方依其專業知識、用戶訪談、市場調查等資訊而訂定，特殊應用程式需求者由雙方另行議定。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方有義務甲方使用「本服務」，乙方有義務利用甲方所提供之資料進行分析、統計與研究，以幫助甲方在未來經營管理與行銷方針上作決策判斷與支援。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方於租用應用程式服務期間，對乙方所提供之軟硬體設備及設施擁有其使用權，乙方保留其他所有權利。</span>
        </li>
    </ol>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">六、</span>
        <span style="font-size:18pt">教育訓練</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span > 臨店系統教育訓練與設定乃為一次性服務。倘若甲方因人事異動等因素，必需重新教育訓練人員時，其延伸的費用(到店教學費與車馬費)應由甲方負擔。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span > 乙方有義務對甲方代表人提供線上技術支援及教育說明，其範圍亦侷限於「本服務」內。乙方之立場僅為教學及疑難排解，並無義務代替甲方輸入任何資料，甲方應負責其資料 建置與整理。</span>
        </li>
        <table class="pay-area">
            <tr>
                <th>區域</th>
                <th>費用</th>
            </tr>
            <tr>
                <td>全台除離島</td>
                <td>2,000 元/店/次</td>
            </tr>
            <tr>
                <td>離島地區</td>
                <td>依照交通與住宿費用而定</td>
            </tr>
        </table>
    </ol>
    <p style="margin-top:8pt; margin-bottom:8pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">七、</span>
        <span style="font-size:18pt">付款方式</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">甲方應於合約簽訂日期後起算，於十五日內支付款項，乙方提供金流支付系統平台( 信用卡/分期、ATM櫃員機、網路ATM、四大超商代碼/條碼 )提供甲方匯款，甲方於使用「本服務」一個月後，乙方應於第二個月首週前與甲方核對款項無誤後交付發票予甲方。</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">直接匯款資料如下：</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">銀行資料：國泰世華銀行 013 林口分行</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">帳號：134030-005119</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">帳戶：安騰創新生活科技有限公司</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style=" font-size:12pt">&nbsp;</span>
    </p>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">八、</span>
        <span style="font-size:18pt">提前解約</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span>倘若甲方於「本服務」六個月內申請解約，需扣抵六個月軟體租用費用作為賠償乙方之違約金額，雲端開通費用、週邊設備費用、客製化服務費用、加值服務費用 則不予以退還。超過六個月則不與申請解約。</span>
        </li>
    </ol>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">九、</span>
        <span style="font-size:18pt">責任與保證</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方同意在取得乙方之授權後，忠實履行本合約約定之義務，若有任何違約行為，願接受乙方解除合約之處分。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方承諾依照乙方服務使用操作說明，妥善使用「本服務」，不得有任何違法或不當之使用，包括但不限於：上傳非法之圖文資料、上傳與業務無關之私人資料、短時間內上傳大量資料致影響軟體系統之效能等。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方得依用戶的使用模式、用量、市場反應或公司營運策略，得在不事先告知甲方的前提，進行系統整體功能或效能的提升。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方對於甲方使用「本服務」而上傳之資料，負保密義務，</span>
            <span >不得洩漏予他人，並遵循中華民國《個人資料保護法》(附件一)相關要求及保障個人資料提供者當事權之權利，乙方於租賃期間應嚴守遵循中華民國《個人資料保護法》(附件一)相關要求及保障個人資料提供者當事權之權利，乙方除有不可抗力事件，包含但不限於遭惡意程式攻擊，應盡力預防資料外洩。甲方亦不得將「本服務」洩漏與甲方無關第三方者或無權限之人，倘甲方因違反《個人資料保護法》，致個人資料遭不法蒐集、處理、利用或其他侵害當事人權利者，負損害賠償責任。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span style=" text-decoration:underline">甲方使用「本服務」而上傳之資料，其所有權屬甲方所有，甲方需落實顧客個人資料保護及管理措施，並遵循中華民國《個人資料保護法》(附件一)相關要求及保障個人資料提供者當事者之權利，倘甲方因違反《個人資料保護法》，致個人資料遭不法蒐集、處理、利用或其他侵害當事人權利者，負損害賠償責任．</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span style=" text-decoration:underline">倘若未來甲乙雙方不再續約，乙方應提供甲方租賃期間所產生之「本服務」資料備份，並交還予甲方。
            </span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方應提供甲方專屬帳號及密碼以使用「本服務」，甲方應妥善管制該帳號及密碼，並限定有權限之人始能登入「本服務」，不得將該帳號及密碼洩漏與甲方無關第三方者或無權限之人。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span style=" text-decoration:underline">甲方不得將帳號提供給與甲方無關第三方者或無權限之人，亦不得違反上開個人資料保護及管理措施，如有違反致使甲方資料外洩(附件二)，概與乙方無涉。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方不得將向乙方租賃之系統帳號移轉或交付第三方公司使用。甲方不得轉銷售或轉租賃帳號給甲方公司員工、第三方公司或個人。違反此項規定者，帳號將被終止，且不退款。情節重大者，乙方得依實際狀況向甲方提出損害賠償。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方租用本服務，不得妨礙他人使用或影響乙方系統之正常運作。如經乙方察覺或經人舉發有違反前述約定之情事時，經乙方通知後 </span>
            <span >48小時內未改善或移除者，乙方得終止其使用。</span>
        </li>
        <li style="margin-left:44pt; line-height:14pt; padding-left:6pt; font-size:12pt">
            <span>乙方保證「本服務」係由乙方自主開發銷售，權利悉歸乙方所有，無任何侵害他人權利之事。如有違反，致侵害他人權利者，應由乙方自行負責，概與甲方無涉。</span>
        </li>
        <li style="margin-left:44pt; line-height:14pt; padding-left:6pt; font-size:12pt">
            <span >甲方不得使用乙方所提供的服務進行干擾，破壞，非法，侵犯及侵占他人及財產的行為。上述不法行為之界定，完全由乙方認定。</span>
        </li>
        <li style="margin-left:44pt; line-height:14pt; padding-left:6pt; font-size:12pt">
            <span >甲方透過乙方服務所公開的資訊必須皆為合法，無盜版、盜用、侵犯智慧財產權或誹謗之虞。</span>
        </li>
        <li style="margin-left:44pt; line-height:14pt; padding-left:6pt; font-size:12pt">
            <span >甲方使用應用程式而儲存於乙方伺服器上之資料，甲方應自行承擔該資料來源的合法性，乙方不負責該資料內容的任何法律責任。 </span>
        </li>
        <li style="margin-left:44pt; line-height:14pt; padding-left:6pt; font-size:12pt">
            <span >乙方如因情勢變更，必要時得暫停或終止「本服務」全部或部份之經營，甲方不得異議或要求任何補償。但乙方應於預定暫停或終止之日前三個月公告並通知甲方。</span>
        </li>
    </ol>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">十、</span>
        <span style="font-size:18pt">系統客製化</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方同意於甲方承租的系統範圍內有條件修改系統以符合甲方營運之需求；乙方保留系統最終的修改權利。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >系統客製化的範圍僅限甲方承租的「本服務」平台應用，包括與第三方系統資料介接，第三方系統舊資料轉移、手機應用程式功能整合、網路社群媒體整合。</span>
        </li>
    </ol>
    <p style="margin-top:5pt; margin-bottom:5pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">十一、</span>
        <span style="font-size:18pt">合約終止</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style="font-size:12pt">甲乙雙方有下列情事之一者，乙方得立即終止或解除本合約，並依法追訴其違法及不當行為所造成乙方之損失：</span>
    </p>
    <ol type="1" style="margin:0pt; padding-left:0pt">
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >甲方違反本合約之任一條款時，乙方得視情形於通知甲方後暫停提供服務，至甲方改善違約情況時為止。</span>
        </li>
        <li style="margin-left:38pt; line-height:14pt; padding-left:12pt; font-size:12pt">
            <span >乙方如經主管機關撤銷特許執照時，本契約即自動向後失效。乙方應於收受主管機關撤 銷特許執照之正式書面通知日起七日內刊登新聞紙公告，並請甲方於二個月內向乙方辦理無息退還其溢繳或應繳費用之手續。甲方如有其他損害，乙方不負賠償責任。</span>
        </li>
    </ol>
    <p style="margin-top:17pt; margin-bottom:18pt; page-break-after:avoid; line-height:26pt">
        <span style="font-size:20pt">十二、</span><span style="font-size:18pt">其它約定</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style="font-size:12pt">本合約壹式</span>
        <span style="font-size:12pt">貳</span>
        <span style="font-size:12pt">份，甲乙雙方各執</span>
        <span style="font-size:12pt">壹</span>
        <span style="font-size:12pt">份為憑，如有未盡事宜者，均以中華民國法令為準據法。</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style="font-size:12pt">因本合約內容約定條款所發生之糾紛，以臺灣桃園地方法院為第一審管轄法院。</span>
    </p>
    <p style="margin:auto 0pt; page-break-after:avoid; font-size:20pt">
        <br style="page-break-before:always; clear:both">
    </p>
    <hr color="#5d5d5d" style="margin:50px 0">
    <p style="margin:auto 0pt; page-break-after:avoid; font-size:18pt">
        <span style="font-weight:bold">立合約書人</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style="font-size:13pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style="font-size:13pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:14pt">
        <span style="font-size:13pt">&nbsp;</span>
    </p>
    <p style="margin:auto 0pt; line-height:18pt">
        <span style="font-size:13pt; font-weight:bold">　　</span>
        <span style="font-size:18pt">甲方：</span>
    </p>
    <div class="flex-break padding-small" style="justify-content: space-between;width:90%;">
        <div style="max-width:450px">
            <div class="flex-break" style="margin-top:10pt; line-height:11pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:15pt">法人/品牌名稱：</div>
                <div style="font-size:14pt">{{ customerInfo['custname'] }}</div>
            </div>
            <div class="flex-break" style="margin-top:10pt; line-height:11pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:15pt">代 表 人&nbsp;&nbsp;：</div>
                <div style="font-size:14pt">{{ customerInfo['siname'] }}</div>
            </div>
            <div class="flex-break" style="margin-top:10pt; line-height:11pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:15pt">縣市地區：</div>
                <div style="font-size:14pt">{{ customerInfo['addrCity'] }} {{ customerInfo['addrArea'] }}</div>
            </div>
            <div class="flex-break" style="margin-top:10pt; line-height:11pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:15pt">地址：</div>
                <div style="font-size:14pt">{{ customerInfo['address'] }}</div>
            </div>
            <div class="flex-break" style="margin-top:10pt; line-height:11pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:15pt">信箱：</div>
                <div style="margin-bottom:10px;font-size:15pt">{{ customerInfo['email'] }}</div>
            </div>
            <div class="flex-break" style="margin-top:10pt;line-height:11pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:15pt">手機：</div>
                <div style="margin-bottom:10px;font-size:15pt">{{ customerInfo['phone'] }}</div>
            </div>
            <div class="flex-break" style="margin-top:10pt;line-height:11pt; widows:0; orphans:0" *ngIf="customerInfo['taxnum']">
                <div style="margin-bottom:10px;font-size:15pt">統一編號：</div>
                <div style="margin-bottom:10px;font-size: 15pt;">{{ customerInfo['taxnum'] }}</div>
            </div>
        </div>
        <div>
            <div style="width:200px;height:210px;border:1px solid black">
                <img style="width:100%;" src="" alt="">
            </div>
            <span style="font-size:12pt; color:#a5a5a5">代表人簽章</span>
        </div>
    </div>
    <p style="margin-top:10pt; margin-bottom:10pt; line-height:10pt; widows:0; orphans:0">
        <span style="font-size:16pt">&nbsp;</span>
    </p>
    <p style="margin-top:10pt; margin-bottom:10pt; line-height:10pt; widows:0; orphans:0">
        <span style="font-size:16pt">&nbsp;</span>
    </p>
    <p style="margin-top:10pt; margin-bottom:10pt; line-height:10pt; widows:0; orphans:0">
        <span style="font-size:16pt">&nbsp;</span>
    </p>
    <p style="margin-top:10pt; margin-bottom:10pt; line-height:10pt; widows:0; orphans:0">
        <span style="font-size:16pt">&nbsp;</span>
    </p>
    <p style="margin-top:10pt; margin-bottom:10pt; line-height:10pt; widows:0; orphans:0">
        <span style="font-size:16pt">&nbsp;</span>
    </p>
    <p style="margin-top:10pt; margin-bottom:10pt; line-height:10pt; widows:0; orphans:0">
        <span style="font-size:16pt">&nbsp;</span>
    </p>
    <p style="margin-top:20pt;margin-bottom:10pt;  widows:0; orphans:0">
        <span style="font-size:18pt">乙方：</span>
    </p>
    <div class="flex-break padding-small" style="justify-content: space-between;width:90%;">
        <div style="max-width:450px">
            <div class="flex-break" style="margin-top:10pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:14pt">法&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 人 ：</div>
                <div style="margin-bottom:20px;font-size:14pt">安騰創新生活科技有限公司</div>
            </div>
            <div class="flex-break" style="line-height:10pt; widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:14pt">統一編號：</div>
                <div style="margin-bottom:10px;font-size:16pt">83575163</div>
            </div>
            <div class="flex-break" style=" widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:14pt">代&nbsp;表&nbsp;人：</div>
                <div style="margin-bottom:10px;font-size:14pt">張齡予</div>
            </div>
            <div class="flex-break" style=" widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:14pt">地　&nbsp;&nbsp; 址：</div>
                <div style="margin-bottom:10px;font-size:14pt">桃園市龜山區文興路146號4樓之2</div>
            </div>
            <div class="flex-break" style=" widows:0; orphans:0">
                <div style="margin-bottom:10px;font-size:14pt">電&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; 話：</div>
                <div style="margin-bottom:10px;font-size:14pt">03-3270101</div>
            </div>
        </div>
        <div>
            <div style="min-width:270px;max-width:290px;height:210px;border:1px solid black">
                <img style="height:85%;" src="{{bigUrl}}">
                <img style="height:40%;margin-left:10px;" src="{{smallUrl}}">
                <!-- <img style="height:85%;" src="./assets/images/online-contract/sealb.png" >
                <img style="height:40%;margin-left:10px;" src="./assets/images/online-contract/seals.png" > -->
            </div>
            <span style="font-size:12pt; color:#a5a5a5">代表人簽章</span>
        </div>
    </div>
    <p style="margin-top:10pt; margin-bottom:10pt;  widows:0; orphans:0">
        <span style="font-size:14pt">&nbsp;</span>
    </p>
    <p style="margin-top:10pt; margin-bottom:10pt;  widows:0; orphans:0">
        <span style="font-size:14pt">&nbsp;</span>
    </p>
    <p style="margin-top:5pt; margin-bottom:1pt;  widows:0; orphans:0">
        <span style="font-size:14pt">　　</span>
        <span style="width:2.22pt; display:inline-block">&nbsp;</span>
        <span style="width:24pt; display:inline-block">&nbsp;</span>
    </p>
    <p style="margin-top:3pt; margin-bottom:20pt; text-align:justify; line-height:7pt; widows:0; orphans:0">
        <span style="font-size:18pt">&nbsp;</span>
    </p>
    <p style="margin-top:3pt; margin-bottom:20pt; text-align:justify; line-height:7pt; widows:0; orphans:0">
        <span style="font-size:18pt">&nbsp;</span>
    </p>
    <div class="suad-info">
        <div style="font-size:13pt">業務代表：{{ suadInfo['sudnam'] }}</div>
        <div style="font-size:13pt">業務電話：{{ suadInfo['phone_'] }}</div>
    </div>
    <p style="margin-top:3pt; margin-bottom:20pt; text-align:justify; line-height:7pt; widows:0; orphans:0">
        <span style="font-size:18pt">&nbsp;</span>
    </p>
    <p style="margin-top:5pt; margin-bottom:20pt; text-align:center; line-height:21pt">
        {{ today }}
        <br style="page-break-before:always; clear:both">
    </p>
</div>
